<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle"></Heading>
    <small> View {{ myTitle }} </small>
    <hr />
    <div class="text-center mt-3 mb-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-table class="mt-4" :data="teachers" style="min-width: 100%" border>
      <el-table-column label="Name">
        <template slot-scope="scope">
          <template>
            <b>
              {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
            </b>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="email" label="Email" width="350">
        <template slot-scope="scope">
          <b class="text-info">{{ scope.row.email }}</b>
        </template>
      </el-table-column>
      <el-table-column label="Pro (1/2)" width="120">
        <span slot="header">
          <span style="color: orange;"> <i class="fas fa-crown" /> Pro </span>
          (<span>2</span>/<span class="text-success">50</span>)
        </span>
        <template slot-scope="scope">
          <b
            v-if="(scope.row.all_roles || []).includes('pro')"
            style="color: orange;"
          >
            <span class="proIcon">
              <i class="fas fa-crown" />
              Pro
            </span>
          </b>
          <b v-else>
            <el-tooltip
              class="item"
              effect="dark"
              content="Click to Upgrade 12 MONTHS Pro"
              placement="top"
            >
              <el-button
                type="info"
                @click="assignYearlyPro(scope.row.id)"
                size="small"
              >
                Free
              </el-button>
            </el-tooltip>
          </b>
        </template>
      </el-table-column>
      <el-table-column label="Role" width="200">
        <template slot-scope="scope">
          <span v-for="(role, index) in scope.row.all_roles" :key="index">
            <el-tooltip
              class="item"
              effect="dark"
              content="Admin"
              placement="top"
            >
              <el-tag
                v-if="role === 'company-admin'"
                type="success"
                class="mr-2"
              >
                <i class="fas fas fa-building"></i>
              </el-tag>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="Teacher"
              placement="top"
            >
              <el-tag v-if="role === 'teacher'" type="success" class="mr-2">
                <i class="fas fa-chalkboard-teacher"></i>
              </el-tag>
            </el-tooltip>
          </span>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Student"
            placement="top"
          >
            <el-tag type="success" class="mr-2">
              <i class="fas fa-user"></i>
            </el-tag>
          </el-tooltip> -->
          <el-button type="text" @click="selectRole(scope.row)">
            <i class="fa fa-edit"></i>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Date Registered" width="150">
        <template slot-scope="scope">
          {{ instant.formatGoogleTime(scope.row.created_at) }}
        </template>
      </el-table-column>
      <el-table-column label="IP" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.last_ip">
            <template v-if="scope.row.last_iso">
              ({{ scope.row.last_iso }})
            </template>
            {{ scope.row.last_ip }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mt-3">
      <el-pagination
        background
        @current-change="setPage"
        :page-size="pageSize"
        :current-page="page"
        :total="resultsTotal"
        :pager-count="10"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="Edit Role"
      v-if="activeUser"
      :visible.sync="showRole"
      width="30%"
    >
      <el-form label-width="100px">
        <el-form-item label="User">
          <b>
            {{ user.displayName(activeUser.first_name, activeUser.last_name) }}
            ({{ activeUser.email }})
          </b>
        </el-form-item>
        <el-form-item label="Select Role">
          <el-checkbox v-model="isTeacher">
            <i class="fas fa-chalkboard-teacher"></i>
            Teacher
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <div class="text-right">
            <el-button type="success" @click="saveRoles">
              Save
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Company from "@/apis/company";
import SAT from "@/apis/sat";
import userApi from "@/apis/user";
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: ` ${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      teachers: [],
      isTeacher: false,
      activeUser: null,
      companies: null,
      showCompanyModal: false,
      resultsTotal: null,
      showRole: false,
      pageSize: 10
    };
  },
  computed: {
    ...mapState("user", ["token", "lang"]),
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    myTitle() {
      return "Users";
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    companyId() {
      return this.getProfile.company_id;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {
    companyId() {
      this.getUsers();
    }
  },

  mounted() {
    if (this.$route.query.search) {
      this.studentQuery = this.$route.query.search;
    }
    // this.searchTeachers();
    this.getUsers();
  },

  methods: {
    async getUsers(query) {
      const students = await Company.getUsers({
        keyword: query !== "" ? query : null,
        per_page: 50,
        page: this.page,
        sort: "name"
      });
      this.teachers = students.users.data;
      this.resultsTotal = students.users.total;
      this.pageSize = students.users.per_page;
    },
    searchUsers() {
      this.routerPush({
        params: { ...this.$route.params },
        query: {
          ...this.$route.query,
          page: 1,
          search: this.studentQuery !== "" ? this.studentQuery : null
        }
      });
    },
    setPage(page) {
      this.routerPush({
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    selectRole(user) {
      this.activeUser = null;
      this.activeUser = user;
      this.showRole = true;
      if (user.all_roles.indexOf("teacher") > -1) {
        this.isTeacher = true;
      } else {
        this.isTeacher = false;
      }
    },
    async saveRoles() {
      await Company.companySetTeacher({
        user_id: this.activeUser.id,
        assign_teacher: this.isTeacher
      });
      this.$message({
        message: "Success!",
        type: "success"
      });
      // window.location.reload();
      this.getUsers();
      this.showRole = false;
      // this.isTeacher = false;
    }
  }
};
</script>

<style scoped></style>
